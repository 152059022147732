// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
};

export const USER_POOL_ID = 'ap-northeast-1_ePjlamIpv';
export const CLIENT_ID = '53vldbjnqts44miaf491jferm1';
export const REGION = 'ap-northeast-1';
export const ACCESS_KEY_ID = 'AKIAQWW63P2YSYIXQOGI';
export const SECRET_ACCESS_KEY = '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';

export const API_URL = 'https://6gakfsq9fc.execute-api.ap-northeast-2.amazonaws.com/test';
export const API_KEY = 'V2wavfRe05m7YZD2h96haW9e4rnzI76zIWXNG+Hp';
