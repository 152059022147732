import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-common-error',
  templateUrl: './common-error.component.html',
  styleUrls: ['./common-error.component.scss'],
})
export class CommonErrorComponent implements OnInit, OnDestroy {
  public changePasswordForm: FormGroup;
  public Status;
  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    public translate: TranslateService,
    private route: ActivatedRoute,
  ) {}
  public logoUrl;
  ngOnInit() {
    this.logoUrl = environment.production ? 'assets/img/fit365_logo.svg' : 'assets/img/fit365_logo_test_mode.svg';

    this.renderer.addClass(document.querySelector('app-root'), 'company-page');
    this.changePasswordForm = new FormGroup({
      password: new FormControl(null, Validators.required),
      password2: new FormControl(null, Validators.required),
      retypePassword: new FormControl(null, Validators.required),
    });
    
    this.Status = this.route.snapshot.paramMap.get('status');
      if(this.Status == 404){
        document.getElementById('404').style.display='block';
        document.getElementById('400').style.display='none';
        document.getElementById('500').style.display='none';
      }else if(this.Status == 400){
        document.getElementById('404').style.display='none';
        document.getElementById('400').style.display='block';
        document.getElementById('500').style.display='none';
      }else if(this.Status == 500){
        document.getElementById('404').style.display='none';
        document.getElementById('400').style.display='none';
        document.getElementById('500').style.display='block';
      }
  }

  changePassword() {
    if (this.changePasswordForm.valid) {
      this.appService.changePassword();
    } else {
      this.toastr.error('Please insert the password!', 'Error!');
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'company-page'
    );
  }
}
