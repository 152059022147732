<div style="position: absolute;top: 0;left: 0;width: 100%;">
  <nav class="navbar navbar-expand navbar-color navbar-light" style="margin-left: 0px;height: 80px;">
    <a [routerLink]="['/']">
    <img
        src={{logoUrl}}
        alt="AdminLTE Logo"
        class="brand-image"
        style="margin-top: -15px;margin-bottom: -10px;"
      /></a>
  </nav>
</div>
<div class="login-box" style="margin-top: 80px;">
  <div class="login-logo">
    <!-- <i class="fas fa-check circle-icon" style='font-size:30px; color: white'></i> -->
    <img src="assets/img/グループ 41.svg"/>
  </div>
  <div class="login-logo">
    <p class="request-title">{{ 'CONFIRM.TITLE_1' | translate }}</p>
  </div>
  <div class="text-center" style="margin-top: 40px;">
    <p class="request-title" style="font-size: 16px;">{{ 'CONFIRM.CONFIRM_TXT_1' | translate }}<br>{{ 'CONFIRM.CONFIRM_TXT_2' | translate }}<br>{{ 'CONFIRM.CONFIRM_TXT_3' | translate }}</p>
  </div>  
</div>