<div style="position: absolute; top: 0; left: 0; width: 100%">
  <nav
    class="navbar navbar-expand navbar-color navbar-light"
    style="margin-left: 0px; height: 80px"
  >
    <a [routerLink]="['/']">
      <img
        src={{logoUrl}}
        alt="AdminLTE Logo"
        class="brand-image"
        style="margin-top: -15px; margin-bottom: -10px"
    /></a>
  </nav>
</div>
<div class="login-box" style="margin-top: 80px">
  <div class="login-logo">
    <!-- <i class='far fa-comment circle-icon' style='font-size:30px; color: white;'></i> -->
    <img src="assets/img/グループ 42.svg" />
  </div>
  <div class="login-logo">
    <p class="request-title">{{ 'REQUEST.TITLE' | translate }}</p>
  </div>
  <div class="text-center" style="margin-top: 40px; margin-bottom: 40px">
    <!-- <p class="request-title" style="font-size: 16px;">{{ 'REQUEST.REQUEST_TXT_1' | translate }}<br>{{ 'REQUEST.REQUEST_TXT_2' | translate }}</p> -->
    <p class="request-title" style="font-size: 16px">{{ JUDGE_DISP_CAUSE }}</p>
  </div>
  <div class="row">
    <div class="col-12 text-center">
      <a
        (click)="registeredit()"
        class="btn btn-primary btn-lg round request-button"
      >
        {{ 'REQUEST.REQUEST_BUTTON' | translate }}
      </a>
    </div>
  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#f77fb1 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #f77fb1 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>