<div style="position: absolute;top: 0;left: 0;width: 100%;">
  <nav class="navbar navbar-expand navbar-color navbar-light" style="margin-left: 0px;height: 80px;">
    <a [routerLink]="['/']">
    <img
        src={{logoUrl}}
        alt="AdminLTE Logo"
        class="brand-image"
        style="margin-top: -15px;margin-bottom: -10px;"
      /></a>
  </nav>
</div>
<div class="login-box" style="margin-top: 80px;">
  <div class="login-logo">
    <!-- <i class='far fa-envelope circle-icon' style='font-size:30px; color: white;'></i> -->
    <img src="assets/img/グループ 38.svg"/>
  </div>
  <div class="login-logo">
    <p class="reques-class" style="font-size: 20px;font-weight: 500;color: #3E3E3E;">{{ 'EMAIL_REGISTER.TITLE' | translate }}</p>
  </div>
  <div class="text-center" style="margin-top: 40px;" id="first">
    <p class="reques-class" style="font-size: 16px;color: #3E3E3E;white-space: break-spaces;">{{ 'EMAIL_REGISTER.EMAIL_REGISTER_BEFORE_TXT_1' | translate }}<br>{{ 'EMAIL_REGISTER.EMAIL_REGISTER_BEFORE_TXT_2' | translate }}</p>
  </div>
  <div class="text-center" style="margin-top: 40px;display: none;" id="second">
    <p class="reques-class" style="font-size: 16px;color: #3E3E3E;">{{ 'EMAIL_REGISTER.EMAIL_REGISTER_AFTER_TXT_1' | translate }}<br>{{ 'EMAIL_REGISTER.EMAIL_REGISTER_AFTER_TXT_2' | translate }}</p>
  </div>
  <!-- /.login-logo -->
 
      <form [formGroup]="registerForm" (ngSubmit)="register()" style="margin-top: 40px;">
        
        <div class="input-group mb-3">
          <div class="input-group-append">
            <div class="input-group-text" style="border-bottom-left-radius: 5px;border-top-left-radius: 5px;">
              <!-- <span class="far fa-envelope" style="color: white;padding-left: 8px;width: 20px;height: 20px;"></span> -->
              <img src="assets/img/グループ 39.svg" style="margin: auto;width: 20px;height: 20px;"/>
            </div>
          </div>
          <input
            formControlName="email"
            type="email"
            class="form-control"
            placeholder="{{ 'EMAIL_REGISTER.EMAIL_REGISTER_EMAIL_PLACEHOLDER' | translate }}"
            (ngModelChange)="change()"
            [(ngModel)]="email"
            id="email"
          />
        </div>
        <div class="text-center">
          <div class="text1 text-center"  style="height: 34px;">
            <div id="error">
              <p id="errMsg" style="font-family: 'Noto Sans JP', sans-serif;
              font-weight: 500 !important;"></p>
            </div>
          </div>
          <div class="display" style="margin-top: 6px;">
            <a (click)="register()" class="btn btn-primary btn-lg round btn-font">
              {{ 'EMAIL_REGISTER.EMAIL_REGISTER_BUTTON' | translate }}
            </a>
          </div>
        </div>
      </form>      
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#f77fb1 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #f77fb1 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>