<div style="position: absolute;top: 0;left: 0;width: 100%;">
  <nav class="navbar navbar-expand navbar-color navbar-light" style="margin-left: 0px;height: 80px;">
    <a [routerLink]="['/']">
    <img
        src={{logoUrl}}
        alt="AdminLTE Logo"
        class="brand-image"
        style="margin-top: -15px;margin-bottom: -10px;"
      /></a>
  </nav>
</div>

<div id="404" class="login-box" style="margin-top: 80px; display: none;">
  <div class="login-logo">
    <!-- <i class="fas fa-exclamation-triangle circle-icon" style='font-size:30px; color: white'></i> -->
    <img src="assets/img/グループ 40.svg"/>
  </div>
  <div class="login-logo">
    <p cla style="font-size: 20px; color:#F85F58;">{{ 'COMMON.TITLE' | translate }}</p>
  </div>
  <div class="text-center" style="margin-top: 40px;">
    <p style="font-size: 16px;color: #3E3E3E;">{{ 'COMMON.ERROR_404' | translate }}</p>
  </div>  
</div>
<div id="400" class="login-box" style="margin-top: 80px; display: none;">
  <div class="login-logo">
    <!-- <i class="fas fa-exclamation-triangle circle-icon" style='font-size:30px; color: white'></i> -->
    <img src="assets/img/グループ 40.svg"/>
  </div>
  <div class="login-logo">
    <p class="request-title" style="font-size: 20px; color:#F85F58;">{{ 'COMMON.TITLE' | translate }}</p>
  </div>
  <div class="text-center" style="margin-top: 40px;">
    <p class="request-title" style="font-size: 16px;color: #3E3E3E;">{{ 'COMMON.ERROR_400' | translate }}</p>
  </div>  
</div>
<div id="500" class="login-box" style="margin-top: 80px; display: none;">
  <div class="login-logo">
    <!-- <i class="fas fa-exclamation-triangle circle-icon" style='font-size:30px; color: white'></i> -->
    <img src="assets/img/グループ 40.svg"/>
  </div>
  <div class="login-logo">
    <p class="request-title" style="font-size: 20px; color:#F85F58;">{{ 'COMMON.TITLE' | translate }}</p>
  </div>
  <div class="text-center" style="margin-top: 40px;">
    <p class="request-title" style="font-size: 16px;color: #3E3E3E;">{{ 'COMMON.ERROR_500' | translate }}</p>
  </div>   
</div>