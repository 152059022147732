import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './pages/main/main.component';
import { HeaderComponent } from './pages/main/header/header.component';
import { FooterComponent } from './pages/main/footer/footer.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CompanyRegisterComponent } from './pages/company-register/company-register.component';
import { EmailRegisterComponent } from './views/email-register/email-register.component';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { AppButtonComponent } from './components/app-button/app-button.component';

import { DatePipe, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { ConfirmComponent } from './pages/confirm/confirm.component';
import { Requests } from './views/request/request.component';
import { CompanyEditComponent } from './pages/company-register-edit/company-register-edit.component';
import { EditConfirmComponent } from './pages/edit-confirm/edit-confirm.component';
import { EditWarningComponent } from './pages/edit-warning/edit-warning.component';

import {AppliedErrComponent} from './pages/applied-error/applied-error.component'
import {
  HttpClientModule,
  HttpClient,
  HttpHandler,
  HttpClientJsonpModule,
} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DataProvider } from '../providers/data/data';
import { CallapiService } from '../providers/rest/callapi.service';
import { CommonErrorComponent } from './pages/common-error/common-error.component';

import { NgxSpinnerModule } from 'ngx-spinner';

registerLocaleData(localeEn, 'en-EN');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    './assets/i18n/',
    '.json?v=' + new Date().getTime()
  );
}

@NgModule({
  declarations: [
    EditConfirmComponent,
    AppComponent,
    MainComponent,
    ConfirmComponent,
    HeaderComponent,
    FooterComponent,
    CompanyEditComponent,
    CompanyRegisterComponent,
    AppliedErrComponent,
    EditWarningComponent,
    EmailRegisterComponent,
    AppButtonComponent,
    Requests,
    CommonErrorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientJsonpModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    NgbModule,
    NgxSpinnerModule
  ],
  providers: [DataProvider, CallapiService, DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
